FD.Page = function() {
    'use strict';

    const $overlay = $('[data-fd-toggle-target="product-filters"]');
    
    function updateFilterHeader() {
        const total = $overlay.find('.filter.is-selected').length;
        $overlay.find('.product-filters-header')
            .toggleClass('visibility-hidden', total === 0)
            .find('.label-static').text(`Filters (${total})`);
    }

    function setupAutocomplete() {
        const $search = $('[data-search-container]');       
        const $input = $search.find('input');
        const $results = $search.find('[data-search-results]');
        const $items = $search.find('[data-search-items]');
        const $blank = $search.find('[data-search-blank-state]');
        const { Division, CustomSegment } = FD.Utility.getControlValuesByContainer("#SEARCH");
        let prevQuery = $input.val();
        let activeTimeout;
        const getResultMarkup = function (query, results) {
            if (!query) return '';
            return results.map(({ url, text, param }, index) =>
                `<li><a href="${param.url}?back=${FD.Page.backUrl}">${text}</a></li>`
            ).join('');
        }
        const updateResults = function (query, results) {
            $results.toggleClass('is-loading', false);
            $blank.toggleClass('d-none', results.length > 0);
            $items.toggleClass('d-none', results.length === 0);
            $items.html(getResultMarkup(query, results));
        }

        const fetchResults = FD.Utility.debounceAjax(() => {
            const query = $input.val();
            if (prevQuery === query) return;
            prevQuery = query;

            if (!query) {
                updateResults(query, []);
                return;
            }
            return $.fdAjax({
                method: "GET",
                url: `${FD.Page.autoComplete}?${$.param({ Division,CustomSegment, Search: query})}`,
                callback: (response) => {
                    updateResults(query, response.data.results);
                }
            });
        }, 250);

        function update(e) {
            if (e?.key === 'Enter') {
                e.preventDefault();
                return;
            }
            $results.toggleClass('is-loading', true);
            setTimeout(() => {
                fetchResults();
            });
        }
        $input.on('keydown', update);

        $input.on('change', update);

        $input.on('focus', () => {
            clearTimeout(activeTimeout);
            $results.toggleClass('active', true);
        });

        $input.on('blur', () => {
            //activeTimeout = setTimeout(() => {
            //    $results.toggleClass('active', false);
            //},250)
        });

        update();
    }

    const child = {
        init: function() {
            updateFilterHeader();
            setupAutocomplete();
        },
        clearAllFilters: function() {
            $('[data-fd-toggle-target="product-filters"] .filter.is-selected')
                .closest('.filter-group')
                .each(function() {
                    const $this = $(this);

                    $this.find('.clear').addClass('hidden');                    
                    $this.find('.filter').toggleClass('is-selected', false)
                        .find('input').prop('disabled', true);
                });
            
            updateFilterHeader();
        },
        clearFilters: function(_,e) {
            e.preventDefault();
            this.element.addClass('hidden')
                .closest('.filter-group')
                .find('.filter').toggleClass('is-selected', false)
                .find('input').prop('disabled', true);
            
            updateFilterHeader();
        },
        toggleFilter: function () {
            const $input = $("input", this.element);
            const isSelected = $input.prop("disabled"); // flip selection
            $input.prop("disabled", !isSelected);
            this.element.toggleClass("is-selected", isSelected);
            const $group = this.element.closest('.filter-group');
            $group.find('.clear').toggleClass('hidden', ! $group.find('.is-selected').length );
            updateFilterHeader();
        },
        applyFilters: function () {
            $('input[name$=PageNumber]').val(1);
            this.element.closest('form').submit();
        },
        openProjectModal: function (xhr) {
            FD.Utility.stopLadda.call(this);
            FD.Modal.openModal(xhr, {
                onCreate: function($modal) {                    
                    var $newProject = $modal.find('#newProject');
                    $modal.find('#project').on('change', function() {
                        $newProject.toggleClass("d-none", this.value > 0);
                    });
                }
            });
        },
        saveToProject: function (data, e) {
            e.preventDefault();
            var element = e.target;
            const submit = () => {
                $.fdAjax({
                    url: FD.Page.saveToProjectAction,
                    params: $(element).serialize(),
                    contentTypeHelper: 'form',
                    callback: (result) => {
                        $('#FD_MODAL').modal('hide');
                        const productId = $('#AddToProject_Customization_ProductId').val();
                        const $actions = $(`[data-product-itemid=${productId}] .product-grid-item-actions`);
                        
                        const $newLink = $(`<a title="Added To Project" data-placement="top" class="svg-icon active" href=${FD.Page.projectUrl.replace('0', result.data)} target="blank"></a>`)
                            .html($actions.find('a')[0].innerHTML)
                            .tooltip();
                        $actions.html($newLink);
                    }
                });
            }
            
            if ($("#project").val() == "0") {
                if ($(element).valid()) {
                    const name = $('#newProject_Name').val();

                    $.fdAjax({
                        url: FD.Page.addProjectAction,
                        params: $(element).serialize(),
                        contentTypeHelper: "form",
                        callback: (result) => {
                            $("#project").append($(`<option value="${result.data}">${name}</option>`));
                            $("#project").val(result.data);
                            submit();
                        }
                    });
                }
            } else {
                submit();
            }
        },

    };

    return Object.assign({}, FD.Page, child);
}();